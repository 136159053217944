import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"Maven_Pro\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"mavenPro\"}");
;
import(/* webpackMode: "eager" */ "/app/app/styles/global.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/tippy.js/dist/tippy.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
